<template>
  <div class='scales'>
    <div class='scales__sidebar'>
      <Sidebar />
    </div>
    <div class='scales__content'>
      <h1 class='scales__content__tittle'>Scale Rates</h1>
      <hr class='scales__content__separator' />
      <div
        class='d-flex justify-content-center align-items-center spinner-container'
        v-if='isLoading'
      >
        <img src='@/assets/gif/spinner.gif' width='200' height='200' />
      </div>
      <div v-else>
        <div class='scales__content__row'>
          <div class='d-flex justify-content-end' style="width:100%; gap: 10px;">
          <b-overlay :show='busy' rounded opacity='0.6' spinner-small class='d-flex'>
            <b-form-file
          accept=".xlsx, .xls"
          placeholder="Upload Excel File"
          v-model="file"
          :disabled="!verifyFieldsSend || !sonValid"
        >
        </b-form-file>
        <button @click="clearFile" v-if="file" class="clear-button">
          <b-icon icon="x-circle" scale="1"></b-icon></button>
        </b-overlay>
        <b-overlay :show='busy' rounded opacity='0.6' spinner-small class='d-inline-block'>
            <b-button :disabled='busy' @click='downloadFormat' class='scales__content__row--button'
              ><b-icon icon='cloud-download-fill' aria-hidden='true'></b-icon> Download
              Format</b-button
            >
          </b-overlay>
        </div>
        </div>
        <div class='scales__content__row'>
          <div class='scales__content__row__column'>
            <label for='subject'> <span class='scales__content__required'>*</span>Subject: </label>
            <div class='scales__content__row__autocomplete'>
              <b-form-input
                v-model='subject'
                placeholder='Enter the subject'
                class='scales__content__row__input'
                size='sm'
                style='width: 100%'
                @input='changeSubject()'
              >
              </b-form-input>
              <span
                class='scales__content__row__autocomplete--not-found'
                v-if='requiredFieldSubject'
              >
                This field is required.
              </span>
            </div>
          </div>
        </div>
        <div class='scales__content__row'>
          <div class='scales__content__row__column'>
            <label for='notify'> <span class='scales__content__required'>*</span>Notify To: </label>
            <div class='scales__content__row__autocomplete'>
              <b-form-tags
                input-id='tags-state-event'
                v-model='notify'
                :tag-validator='validator'
                placeholder='Enter valid emails'
                separator=' '
                style='width: 100%'
                size='sm'
                class='scales__content__row__input'
                invalidTagText='Invalid email(s)'
                @input='changeNotifyTo()'
              ></b-form-tags>
              <span
                class='scales__content__row__autocomplete--not-found'
                v-if='requiredFieldNotify'
              >
                This field is required.
              </span>
            </div>
          </div>
          <div class='scales__content__row__column'>
            <label for='carriers'>Carriers Excluded:</label>
            <MultiSelect
              v-model='carriersSelected'
              :options='allCarriers'
              dataKey='id'
              optionLabel='carrier_name'
              placeholder='Select carriers'
              class='scales__content__row__input'
              v-tooltip.bottom="'Please select the carriers that are not going to be included'"
            >
              <template #option='slotProps'>
                <div>
                  <span>
                    {{ slotProps.option.carrier_scac }} - {{ slotProps.option.carrier_name }}
                  </span>
                </div>
              </template>
            </MultiSelect>
          </div>
        </div>
        <div class='scales__content__row'>
          <div class='scales__content__row__column'>
            <label for='customer'>
              <span class='scales__content__required'>*</span>Customer:
            </label>
            <div class='scales__content__row__autocomplete'>
              <AutoComplete
                forceSelection
                v-model='customer'
                :suggestions='filteredCustomers'
                @complete='searchCustomer($event)'
                field='name'
                class='scales__content__row__input'
                @item-select='changeCustomer()'
                placeholder='Search by name'
                style='width: 100%'
              />
              <span class='scales__content__row__autocomplete--not-found' v-if='customersNotFound'>
                Customer not found.
              </span>
              <span
                class='scales__content__row__autocomplete--not-found'
                v-if='requiredFieldCustomer'
              >
                This field is required.
              </span>
            </div>
          </div>
          <div class='scales__content__row__column'>
            <label for='mode'> <span class='scales__content__required'>*</span>Mode: </label>
            <div class='scales__content__row__autocomplete'>
              <Dropdown
                v-model='modeSelected'
                :options='allModes'
                optionLabel='name'
                style='width: 100%'
                placeholder='Select a mode'
                class='scales__content__row__input'
                :filter='true'
                @change='changeMode()'
              />
            </div>
          </div>
        </div>
        <div class='scales__content__row'></div>
        <hr />
        <div class='scales__content__row'>
          <SendQuoteForm class='scales__content__row__line' ref="sendQuoteForm"
            :scaleData="scaleData" @send-quote="handleSendQuote" :file="file"
            @validation-update="handleValidationUpdate"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import SendQuoteForm from './components/SendQuotesForm.vue';

export default {
  components: {
    Sidebar,
    SendQuoteForm,
  },
  name: 'ScalesRates',
  data() {
    return {
      subject: '',
      notify: [],
      carriersSelected: [],
      customer: '',
      filteredCustomers: null,
      modeSelected: {
        id: 'a0k1I0000005NnyQAE',
        name: 'LTL',
      },
      busy: false,
      allModes: [],
      allCarriers: [],
      customersNotFound: false,
      requiredFieldNotify: false,
      requiredFieldCustomer: false,
      requiredFieldSubject: false,
      isLoading: false,
      validationError: null,
      scaleData: null,
      file: null,
      verifyFieldsSend: true,
      sonValid: true,
    };
  },
  watch: {
    subject(newValue) {
      this.verifyFieldsSend = !!newValue && this.notify.length > 0 && !!this.customer;
    },
    notify(newValue) {
      this.verifyFieldsSend = !!this.subject && newValue.length > 0 && !!this.customer;
    },
    customer(newValue) {
      this.verifyFieldsSend = !!this.subject && this.notify.length > 0 && !!newValue;
    },
  },
  async created() {
    this.isLoading = true;
    if (this.$route.params.resetInfo) {
      this.resetInfo();
    } else {
      this.getAllInfo();
    }
    const modes = await this.$store.dispatch('scale/getAllModes');
    this.allModes = [];
    modes.data.forEach((mode) => {
      this.allModes.push({
        id: mode.id,
        name: mode.name,
      });
    });
    const carriers = await this.$store.dispatch('scale/getAllCarriers');
    this.allCarriers = carriers.data;
    this.isLoading = false;
  },
  methods: {
    resetInfo() {
      const scale = {
        subject: '',
        create_load: false,
        notify: [],
        customer: '',
        mode: {
          id: 'a0k1I0000005NnyQAE',
          name: 'LTL',
        },
        accessorials: [],
        carriers_exclude: [],
      };
      this.$store.commit('scale/resetScale', scale);
    },
    getAllInfo() {
      this.subject = this.$store.getters['scale/showSubject'];
      this.notify = this.$store.getters['scale/showNotifyTo'];
      this.carriersSelected = this.$store.getters['scale/showCarriersExclude'];
      this.customer = this.$store.getters['scale/showCustomer'];
      this.modeSelected = this.$store.getters['scale/showMode'];
    },
    async searchCustomer(event) {
      const params = {
        name: event.query,
      };
      const response = await this.$store.dispatch('scale/getCustomersByName', params);
      if (response.status === 404) {
        this.filteredCustomers = null;
        this.customersNotFound = true;
      } else {
        this.filteredCustomers = response.data;
        this.customersNotFound = false;
      }
    },
    validator(tag) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(tag);
    },
    changeSubject() {
      this.$store.commit('scale/setSubject', this.subject);
    },
    changeNotifyTo() {
      this.$store.commit('scale/setNotifyTo', this.notify);
    },
    changeCarriers() {
      this.$store.commit('scale/setCarriersExclude', this.carriersSelected);
    },
    changeMode() {
      const payload = {
        id: this.modeSelected.id,
        name: this.modeSelected.name,
      };
      this.$store.commit('scale/setMode', payload);
    },
    changeCustomer() {
      this.$store.commit('scale/setCustomer', this.customer);
    },
    async handleSendQuote() {
      const parentValid = await this.verifyFields();
      if (parentValid) {
        this.$refs.sendQuoteForm.validateAllFields();
      }
    },
    handleValidationUpdate(validationResults) {
      const hasErrors = Object.values(validationResults).some((value) => value);
      this.sonValid = !hasErrors;
    },
    verifyFields() {
      const fields = [
        { name: 'notify', value: this.notify.length > 0 },
        { name: 'customer', value: !!this.customer },
        { name: 'subject', value: !!this.subject },
      ];
      let hasErrors = false;
      fields.forEach((field) => {
        const fieldName = field.name;
        const isValid = field.value;
        this[`requiredField${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`] = !isValid;
        if (!isValid) {
          hasErrors = true;
        }
      });
      if (hasErrors || this.validationError) {
        this.$message({
          showClose: true,
          type: 'error',
          message: 'Please fill in all the required fields (*)',
          showConfirmButton: false,
          duration: 2000,
        });
      }
      const scale = {
        subject: this.subject,
        notify_to: this.notify,
        customer: this.customer,
        carriers_exclude: this.carriersSelected,
        mode: this.modeSelected,
      };
      this.scaleData = scale;
      return !hasErrors && !this.validationError;
    },
    downloadFormat() {
      const path = 'https://storage.googleapis.com/scales-v2/Templates/Template-ScalesRates%20(1).xlsx';
      const link = document.createElement('a');
      link.href = path;
      link.download = 'Template-Scales-V2';
      link.click();
    },
    clearFile() {
      this.file = null;
      this.$refs.sendQuoteForm.clearForm();
    },
  },
};
</script>

<style lang='scss' scoped>
.scales {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 98%;
      margin: 2rem auto;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 1.5rem;
      &__column {
        display: flex;
        width: 48%;
        justify-content: space-between;
        align-items: center;
      }
      &__content {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
      }
      &__create-on {
        display: flex;
        width: 48%;
        justify-content: end;
        align-items: baseline;
      }
      &__autocomplete {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: flex-start;
        &--not-found {
          font-size: 11px;
          color: $color-user-busy;
        }
      }
      &--button {
        @include primary-button;
      }
      &--button-next {
        @include primary-button;
        margin: 2rem 0 8rem;
      }
      &__input {
        width: 80%;
        &:hover {
          border-color: $color-primary-button;
        }
      }
      &__line {
        width: 100%;
      }
    }
    &__required {
      color: red;
    }
  }
}
@media (max-width: 900px) {
  .scales {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
.clear-button{
  border: none;
  background-color: transparent;
  z-index: 15;
  top: 0;
  bottom: 0;
  position: absolute;
  right: 80px;
}
:deep(.p-inputtext) {
  width: 100%;
  height: 31px;
}
:deep(.p-inputtext:enabled:hover) {
  border-color: $color-primary-button;
}
:deep(.p-multiselect) {
  height: 31px;
}
:deep(.p-multiselect .p-multiselect-label) {
  padding: 0.2rem 0.5rem;
}
:deep(.p-dropdown .p-dropdown-label) {
  padding: 0.2rem 0.5rem;
}
:deep(.p-multiselect .p-multiselect-label) {
  font-size: 15px;
  text-align: start;
}
:deep(.p-inputtext) {
  font-size: 15px;
  text-align: start;
}
:deep {
.custom-file-input{
    width: 32rem;
}
.custom-file-label {
  &:after{
      background-color: #14a2b8 !important;
      color: #ffffff;
    }
}
}
</style>
