<template>
  <div id="formQuote">
    <div class="row mt-3 ml-0">
      <div class="select-form">
        <el-select class="input-form" :disabled="isFileUploaded" v-model="quote.country_from"
          placeholder="Select one country">
          <el-option v-for="item in countryCodesOptions" :key="item.value"
            :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <span class="select-form__span"> Pickup Zip Code or Location Name </span>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <el-autocomplete :disabled="isFileUploaded" class="input-form" v-model="quote.zip_from"
          :fetch-suggestions="querySearchFrom" :clearable="true" placeholder="Please Input"
          @select="handleSelect($event, 'from')">
          <template slot="prepend">Zip From</template></el-autocomplete>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldZipFrom">
          This field is required.
        </span>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldZipFrom">
          {{ validationMessageZipFrom }}
        </span>
      </div>
      <div class="select-form">
        <el-select class="input-form" v-model="quote.country_to" :disabled="isFileUploaded"
          placeholder="Select one country">
          <el-option v-for="item in countryCodesOptions" :key="item.value"
            :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <span class="select-form__span"> Delivery Zip Code or Location Name </span>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <el-autocomplete :disabled="isFileUploaded" class="input-form" v-model="quote.zip_to"
          :fetch-suggestions="querySearchTo" :clearable="true" placeholder="Please Input"
          @select="handleSelect($event, 'to')">
          <template slot="prepend">Zip To</template></el-autocomplete>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldZipTo">
          This field is required.
        </span>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldZipTo">
          {{ validationMessageZipTo }}
        </span>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <el-input :disabled="isFileUploaded" class="input-form" placeholder="100,700,1000"
          @change="handleWeightBrakesChange" v-model="quote.weight_brakes">
          <template slot="prepend">Weight brake:</template>
        </el-input>
        <span class="scales__content__row__autocomplete--not-found"
          v-if="requiredFieldWeightBrakes">
          This field is required.
        </span>
        <span class="scales__content__row__autocomplete--not-found"
          v-if="requiredFieldWeightBrakes">
          {{ validationMessageWeightBrakes }}
        </span>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <el-select :disabled="isFileUploaded" class="input-form__select"
          v-model="quote.units_weight"
          placeholder="Weight Units">
          <el-option v-for="item in unitsOptions" :key="item.value"
            :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldUnitsWeight">
          This field is required.
        </span>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldUnitsWeight">
          {{ validationMessageUnitsWeight }}
        </span>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <el-input class="input-form" :disabled="isFileUploaded" placeholder="Please Input a density"
          @change="handleDensityChange" v-model="quote.density">
          <template slot="prepend">Density</template>
        </el-input>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldDensity">
          This field is required.
        </span>
      </div>
    </div>
    <b-overlay :show='busy' rounded opacity='0.6' spinner-small class='d-inline-block'>
      <b-button :disabled='busy' class='scales__content__row--button-next' @click='sendScale()'>
        Send qoute
      </b-button>
    </b-overlay>
  </div>
</template>

<script>

export default {
  name: 'SendQuoteForm',
  props: {
    scaleData: {
      type: Object,
      default: () => ({}),
    },
    file: {
      type: File,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      quote: {
        zip_from: null,
        zip_to: null,
        weight_brakes: [],
        units_weight: null,
        density: null,
        rates: null,
        country_from: 'US',
        country_to: 'US',
      },
      quote_copy: null,
      aux_zip_to: null,
      requiredFieldZipFrom: false,
      requiredFieldZipTo: false,
      requiredFieldRates: false,
      requiredFieldWeightBrakes: false,
      requiredFieldDensity: false,
      requiredFieldUnitsWeight: false,
      validationMessageZipFrom: '',
      validationMessageZipTo: '',
      validationMessageRates: '',
      validationMessageWeightBrakes: '',
      validationMessageUnitsWeight: '',
      countryCodesOptions: [
        { label: 'US', value: 'US' },
        { label: 'CA', value: 'CA' },
        { label: 'MX', value: 'MX' },
      ],
      unitsOptions: [
        { label: 'lbs', value: 'lbs' },
        { label: 'kg', value: 'kg' },
        { label: 'ton', value: 'ton' },
      ],
      locations: [],
      busy: false,
      isValid: false,
      parentValid: false,
      isFileUploaded: false,
    };
  },
  watch: {
    scaleData: {
      handler(newVal) {
        Object.assign(this.quote, newVal);
      },
      deep: true,
    },
    quote: {
      handler() {
        const validationResults = this.validateFields();
        this.$emit('validation-update', validationResults);
      },
      deep: true,
    },
    file(newVal) {
      if (newVal instanceof FileList) {
        if (this.$parent.verifyFields()) {
          this.readingFile({ target: newVal });
        }
      } else if (newVal instanceof File) {
        if (this.$parent.verifyFields()) {
          this.readingFile({ target: [newVal] });
        }
      }
    },
  },
  methods: {
    async readingFile() {
      this.$router.push({ name: 'EditScaleRates', params: { file: this.file } });
      this.isFileUploaded = true;
    },
    async querySearch(queryString, cb, property) {
      // eslint-disable-next-line
      const country = this.quote[property];
      const data = {
        country_code: country,
        q: queryString,
      };
      if (queryString.length >= 2) {
        const response = await this.$store.dispatch('scalesRates/getLocations', data);
        if (response && Array.isArray(response)) {
          this.locations = response.map((item) => ({
            value: `${item.city}, ${item.state_code}, ${item.zip_code}`,
            label: item.zip_code,
          }));
        } else {
          this.locations = [];
        }
      }
      cb([...this.locations]);
    },
    async querySearchFrom(queryString, cb) {
      return this.querySearch(queryString, cb, 'country_from');
    },
    async querySearchTo(queryString, cb) {
      return this.querySearch(queryString, cb, 'country_to');
    },
    handleSelect(item, type) {
      let data = {};
      if (item.value && item.value.split(',').length > 2) {
        const value = item.value.split(',')[2];
        if (type === 'from') {
          // eslint-disable-next-line
          data['zip_from'] = value;
          this.quote.from = value;
        } else if (type === 'to') {
          // eslint-disable-next-line
          data['zip_to'] = value;
          this.quote.to = value;
        }
        if (this.quote.from && this.quote.to) {
          data = { ...data, ...{ zip_from: this.quote.from, zip_to: this.quote.to } };
        }
      }
    },
    handleWeightBrakesChange(value) {
      if (Array.isArray(value)) {
        this.quote.weight_brakes = value.join(',');
      } else if (typeof value === 'string') {
        this.quote.weight_brakes = value;
      } else {
        this.quote.weight_brakes = '';
      }
    },
    handleDensityChange(value) {
      if (Array.isArray(value)) {
        this.quote.density = value.join(',');
      } else if (typeof value === 'string') {
        this.quote.density = value;
      } else {
        this.quote.density = '';
      }
    },
    organizeQuoteInArrays() {
      this.quote_copy = {
        zip_from: null,
        zip_to: null,
        weight_brakes: [],
        units_weight: null,
        density: [],
        rates: null,
      };
      const processArray = (value) => {
        if (typeof value === 'string' && value.trim()) {
          return value.split(',').map(Number);
          // eslint-disable-next-line
        } else if (Array.isArray(value)) {
          return value.map(Number);
        }
        return [];
      };
      ['weight_brakes', 'density'].forEach((key) => {
        this.quote_copy[key] = processArray(this.quote[key]);
      });
      this.quote_copy.units_weight = this.quote.units_weight;
      this.quote_copy = { ...this.quote };
    },
    validateFields() {
      const requiredFields = [
        { name: 'ZipFrom', value: !!this.quote.zip_from },
        { name: 'ZipTo', value: !!this.quote.zip_to },
        { name: 'WeightBrakes', value: !!this.quote.weight_brakes.length },
        { name: 'rates', value: !!this.quote.rates },
        { name: 'Density', value: !!this.quote.density },
        { name: 'UnitsWeight', value: !!this.quote.units_weight },
      ];
      const validationResults = {};
      requiredFields.forEach((field) => {
        validationResults[field.name] = field.value;
        this.isValid = field.value;
        this[`requiredField${field.name.charAt(0).toUpperCase() + field.name.slice(1)}`] = !field.value && !this.isValid;
      });
      return validationResults;
    },
    validateAllFields() {
      this.parentValid = this.$parent.verifyFields();
      return this.parentValid && this.isValid;
    },
    lineItemsDimension(weightBrakes, density, unitsWeight) {
      const dimensionsArray = [];
      weightBrakes.forEach((weight) => {
        let realWeight = 0;
        let hu = 1;
        let dimensions = null;
        switch (unitsWeight) {
        case 'kg':
          realWeight = weight * 2.205;
          break;
        case 'ton':
          realWeight = weight * 2000;
          break;
        default:
          realWeight = weight;
        }
        do {
          dimensions = realWeight / hu / density;
          // eslint-disable-next-line
          dimensions = dimensions * 1728;
          // eslint-disable-next-line
          dimensions = dimensions ** (1 / 3);
          dimensions = dimensions.toFixed(2);
          // eslint-disable-next-line
          hu++;
        } while (dimensions >= 96);
        dimensionsArray.push({ hu, dimensions, weight });
      });
      return dimensionsArray;
    },
    async sendScale() {
      this.validateFields();
      this.$emit('send-quote', this.isValid);
      const allValid = this.isValid;
      if (allValid && this.parentValid) {
        this.organizeQuoteInArrays();
        const weightBrakesArray = Array.isArray(this.quote_copy.weight_brakes) ? this.quote_copy.weight_brakes : this.quote_copy.weight_brakes.split(',').map(Number);
        const densityArray = Array.isArray(this.quote_copy.density) ? this.quote_copy.density : this.quote_copy.density.split(',').map(Number);
        const dimensions = await this.lineItemsDimension(
          weightBrakesArray,
          densityArray,
          this.quote_copy.units_weight,
        );
        const hauls = this.haulsConstructor(dimensions);
        this.$store.commit('scale/setHauls', hauls);
        this.isLoading = true;
        const response = await this.$store.dispatch('scale/sendQuote');
        if (response.status === 202) {
          this.$swal({
            icon: 'success',
            title: 'The scale has been sent',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.$router.push({ name: 'Scales', params: { resetInfo: true } });
          });
        }
        this.isLoading = false;
      }
    },
    haulsConstructor(dimensions) {
      const hauls = [];
      dimensions.forEach((dimension) => {
        const haul = {};
        haul.line_items = [];
        const lineItems = {
          weight: dimension.weight,
          length: parseFloat(dimension.dimensions),
          width: parseFloat(dimension.dimensions),
          height: parseFloat(dimension.dimensions),
          hu_count: dimension.hu,
        };
        haul.order = hauls.length + 1;
        if ((!this.quote_copy.zip_from && !this.quote_copy.zip_to)
        || (!this.quote_copy.country_from && !this.quote_copy.country_to)
        ) {
          hauls[hauls.length - 1].line_items.push(lineItems);
        } else {
          haul.zip_from = this.quote_copy.from.toString().toUpperCase().trim();
          haul.zip_to = this.quote_copy.to.toString().toUpperCase().trim();
          haul.country_from = this.quote_copy.country_from.toString().toUpperCase();
          haul.country_to = this.quote_copy.country_to.toString().toUpperCase();
          haul.line_items.push(lineItems);
          hauls.push(haul);
        }
      });
      return hauls;
    },
    clearForm() {
      this.quote = {
        zip_from: null,
        zip_to: null,
        weight_brakes: [],
        units_weight: null,
        density: [],
        rates: null,
        country_from: 'US',
        country_to: 'US',
      };
      this.isFileUploaded = false;
    },
  },
};
</script>

<style lang='scss' scoped>
#formQuote {
  margin-top: 50px;
  margin-bottom: 20px;
}
.scales {
  display: flex;
  &__content {
    width: 91%;
    margin-top: 2rem;
    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 1.5rem;
      &--button-next {
        @include primary-button;
        margin: 2rem 0 8rem;
      }
      &__autocomplete {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: flex-start;
        &--not-found {
          font-size: 11px;
          color: $color-user-busy;
          line-height: 20px;
        }
      }
    }
  }
}
#alert {
  margin-top: 30px;
}
.input-form {
  &__select {
    width: 100%;
  }
}
.select-form {
  display: flex;
  flex-direction: column;
  &__span {
    text-align: start;
    font-size: 12px;
    font-style: italic;
  }
}
.row {
  justify-content: space-between;
}
:deep {
  .el-input-group__prepend {
    background-color: #14a2b8;
    color: #ffffff;
  }
  .el-autocomplete {
    width: 100%;
  }
}
</style>
